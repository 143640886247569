import React from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";

const TakeTheReins = () => {
    return (
        <section className="flex items-center justify-center mt-[150px] lg:mt-[250px] mb-40">
            <div className="flex flex-col-reverse sm:flex-row-reverse items-center gap-6 justify-between rounded-[45px]">
                <div className="flex flex-col items-start gap-10 sm:max-w-[60%] h-full">
                    <h2 className="text-xl sm:text-2xl font-bold">
                        Are You Ready to Take the Reins of Your Finances?
                    </h2>
                    <p className="text-base leading-[29px] font-[18px]">
                        Don't let debt hold you hostage any longer. Your voyage
                        towards financial freedom commences today. Fill out the
                        form on this page, and we'll connect you with a trusted
                        debt relief specialist who will accompany you every step
                        of the way. Your brighter financial future is within
                        reach.
                    </p>
                    <div className="self-center sm:self-start">
                        <SharedButton />
                    </div>
                </div>
                <div className="w-[251px] h-[253px] md:w-[280px] md:h-[280px] relative">
                    <Image
                        src={
                            "/clearyourdebtsfast/assets/images/takeTheReins.png"
                        }
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
};

export default TakeTheReins;
