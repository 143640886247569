import React from "react";

function LegalGuidanceIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="126"
            fill="none"
            viewBox="0 0 126 126"
        >
            <circle
                cx="63"
                cy="63"
                r="63"
                fill="#B3CFD6"
                fillOpacity="0.3"
            ></circle>
            <path
                fill="#000"
                d="M63 38c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25zm-.641 1.302v1.042a.643.643 0 00.888.6.64.64 0 00.394-.6v-1.042c6.064.161 11.54 2.605 15.645 6.49l-.721.722a.645.645 0 00.901.921l.742-.741a23.595 23.595 0 016.49 15.665h-1.042a.632.632 0 00-.38.076.643.643 0 00-.094 1.068.645.645 0 00.474.138h1.042a23.661 23.661 0 01-6.47 15.685l-.762-.761a.64.64 0 00-.52-.2.641.641 0 00-.381 1.101l.741.742a23.58 23.58 0 01-15.665 6.49v-1.062a.641.641 0 10-1.282 0v1.062a23.58 23.58 0 01-15.665-6.49l.741-.742a.64.64 0 00-.52-1.101.64.64 0 00-.381.2l-.762.761a23.661 23.661 0 01-6.47-15.685h1.042a.643.643 0 00.6-.888.64.64 0 00-.6-.394h-1.042a23.595 23.595 0 016.49-15.665l.742.741a.645.645 0 10.901-.921l-.721-.722c4.105-3.885 9.581-6.329 15.645-6.49zm.56 4.968a.64.64 0 00-.54.46L59.074 56.05a.647.647 0 101.242.36L63 47.215l2.684 9.194a.649.649 0 00.802.441.647.647 0 00.44-.801L63.621 44.73a.641.641 0 00-.701-.461zm-6.65 14.764a.641.641 0 00-.16.02.651.651 0 00-.04.02L44.73 62.379a.641.641 0 000 1.222l11.338 3.305a.642.642 0 10.36-1.222L47.195 63l9.234-2.704a.642.642 0 00-.16-1.262zm13.362.02a.641.641 0 00-.06 1.242L78.804 63l-9.235 2.684a.64.64 0 10.361 1.222l11.338-3.305a.642.642 0 000-1.222l-11.338-3.305a.641.641 0 00-.3-.02zM63 60.516A2.494 2.494 0 0060.516 63 2.494 2.494 0 0063 65.484 2.494 2.494 0 0065.484 63 2.494 2.494 0 0063 60.516zm0 1.282c.671 0 1.202.53 1.202 1.202 0 .671-.53 1.202-1.202 1.202-.671 0-1.202-.53-1.202-1.202 0-.671.53-1.202 1.202-1.202zm-3.425 7.312a.641.641 0 00-.501.821l3.305 11.318a.641.641 0 001.242 0l3.305-11.318a.647.647 0 10-1.242-.36L63 78.764l-2.684-9.195a.64.64 0 00-.742-.46z"
            ></path>
        </svg>
    );
}

export default LegalGuidanceIcon;
