import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function StreamlinedTheProcess(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-40 gap-8 lg:mt-[250px] mt-24">
            <div className="max-w-[620px] mx-auto lg:mx-0 order-2 lg:order-1">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#71ABBE] ">
                    We've streamlined the debt relief process for your
                    convenience:
                </h2>

                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    <span className="font-bold">Assessment:</span> It all starts
                    with a comprehensive financial assessment. We meticulously
                    examine your debts, income, and financial objectives.
                </p>
                <br />
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    <span className="font-bold">Tailored Plan:</span> Based on
                    the assessment, we craft a customized debt relief plan
                    designed exclusively for your unique circumstances. This
                    plan may include debt settlement negotiations, debt
                    consolidation, credit counseling, or bankruptcy guidance.
                </p>
                <br />
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    <span className="font-bold">Direct Communication:</span> We
                    take the reins in communicating with your creditors to
                    secure advantageous terms on your behalf, such as reduced
                    balances or interest rates.
                </p>
                <br />
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    <span className="font-bold">Dedicated Account:</span> You'll
                    make regular contributions to a dedicated account, which
                    will be used to fund settlements or consolidated payments as
                    your debt relief journey unfolds.
                </p>
                <br />
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    <span className="font-bold">Progress and Freedom:</span> As
                    the process advances, you'll witness your debt shrink,
                    bringing you profound financial relief. In many cases, this
                    path leads to becoming debt-free.
                </p>
                <br />
                <div className="sm:mt-12 mt-8 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[221px] h-[253px] md:w-[405px] md:min-w-[305px] md:h-[600px] sm:min-h-[300px]  relative  mx-auto lg:mx-0 order-1 lg:order-2 lg:mt-12 ">
                <Image
                    src={
                        "/clearyourdebtsfast/assets/images/streamlinedTheProcess.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
