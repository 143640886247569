import React from "react";
import Image from "next/image";

const FinancialFreedom = () => {
    return (
        <section className="flex items-center justify-center mt-[150px] sm:mt-[200px]">
            <div className="flex flex-col-reverse sm:flex-row items-center gap-6 justify-around w-full sm:w-[1200px] rounded-[45px] bg-[#D5E9F0] sm:p-20 p-7">
                <div className="flex flex-col items-start gap-10 sm:max-w-[45%] h-full">
                    <h2 className="text-xl sm:text-2xl font-bold">
                        Your Journey to Financial Freedom Begins Here
                    </h2>
                    <p className="text-base leading-[29px] font-[18px]">
                        Initiating your pursuit of financial freedom is a
                        breeze. Just complete our brief and straightforward
                        form. Doing so lets you connect with a reputable debt
                        relief agency for your cost-free financial consultation.
                        It's a no-risk opportunity to explore your options,
                        uncover possibilities, and break free from the shackles
                        of debt.
                    </p>
                </div>
                <div className="w-[251px] h-[253px] md:w-[355px] md:h-[340px] relative">
                    <Image
                        src={
                            "/clearyourdebtsfast/assets/images/financialFreedom.png"
                        }
                        alt="Image"
                        layout="fill"
                    />
                </div>
            </div>
        </section>
    );
};

export default FinancialFreedom;
