import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
import DebtReductionIcon from "./icons/DebtReductionIcon";
import SimplePaymentIcon from "./icons/SinglePaymentIcon";
import FinancialEmpowermentIcon from "./icons/FinancialEmpowermentIcon";
import LegalGuidanceIcon from "./icons/LegalGuidanceIcon";

const pointsList = [
    {
        icon: <DebtReductionIcon />,
        title: "debt reduction",
        description:
            "Debt relief programs specialize in negotiating with creditors to slash the total amount you owe, making your debts more manageable",
    },
    {
        icon: <SimplePaymentIcon />,
        title: "single payment",
        description:
            "Simplify your financial life by consolidating your debts into a single, stress-free payment.",
    },
    {
        icon: <FinancialEmpowermentIcon />,
        title: "financial empowerment",
        description:
            "Gain invaluable knowledge through expert credit counseling, enabling you to make informed financial decisions.",
    },
    {
        icon: <LegalGuidanceIcon />,
        title: "legal guidance",
        description:
            "Navigate complex bankruptcy proceedings, if required, with the assistance of seasoned professionals.",
    },
];
export default function DebtReliefSavior(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="flex flex-col items-center">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-10 text-[#71ABBE] text-center sm:text-center mt-[50px] mb-[30px] sm:mb-[50px]">
                    How Debt Relief Can Be Your Savior:
                </h2>
                <div className={`${styles["cardsContainer"]}`}>
                    {pointsList.map((point, index) => (
                        <div
                            key={index}
                            className={`flex flex-col items-center p-6 py-8 rounded-[25px] bg-white gap-4 ${styles["cardContainer"]}`}
                        >
                            <span>{point.icon}</span>
                            <p className="text-lg font-extrabold capitalize">
                                {point.title}
                            </p>
                            <p className="lg:text-base lg:leading-8 text-center">
                                {point.description}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="my-10 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
