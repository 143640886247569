import React from "react";

function DebtReductionIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="126"
            fill="none"
            viewBox="0 0 126 126"
        >
            <circle
                cx="63"
                cy="63"
                r="63"
                fill="#B3CFD6"
                fillOpacity="0.3"
            ></circle>
            <path
                fill="#000"
                d="M51.628 69.382v15.91h8.208v-22.59h-8.208v6.68zM51.273 86h-8.918a.356.356 0 01-.355-.354V53.118c0-.195.16-.354.355-.354h8.918c.195 0 .355.16.355.354v8.876h8.563c.195 0 .355.16.355.355v23.297c0 .195-.16.354-.355.354h-8.918zm-.355-.709V53.472H42.71v31.82h8.207zM45.127 43.95l2.265-2.817a.348.348 0 01.506-.044l27.41 21.322 1.289-1.604a.358.358 0 01.604.08l4.68 9.115a.346.346 0 01-.4.496l-9.965-2.569a.35.35 0 01-.187-.558l1.28-1.594-27.42-21.331a.345.345 0 01-.062-.496zm2.602-2.1l-1.82 2.26L73.327 65.43a.352.352 0 01.053.496l-1.163 1.444 8.669 2.241-4.086-7.946-1.164 1.444a.364.364 0 01-.497.062L47.73 41.85zm22.979 29.862H62.5v13.58h8.208v-13.58zm-8.554-.709h8.918c.195 0 .355.16.355.354v14.289c0 .195-.16.354-.355.354h-8.918a.356.356 0 01-.355-.354V71.357a.35.35 0 01.355-.354zm20.278 11.755h-8.207v2.533h8.207v-2.533zm-8.562-.709h8.918c.195 0 .355.16.355.355v3.242c0 .195-.16.354-.355.354H73.87a.356.356 0 01-.356-.354v-3.242c0-.195.16-.355.356-.355z"
            ></path>
        </svg>
    );
}

export default DebtReductionIcon;
