import { ReactElement } from "react";
import styles from "./style.module.scss";

export default function SharedButton(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={() => scrollToTop()}
            className={`bg-[#E0A293]  py-4 sm:px-16 px-4 text-[13px] font-bold sm:text-lg  rounded-[13px] ${styles["animation"]} `}
        >
            Claim Your Complimentary Financial Consultation
        </button>
    );
}
